import cx from "classnames"
import { graphql } from "gatsby"
import React from "react"

import { Helmet } from "react-helmet"
import CategoriesFilterBlog from "../../components/CategoriesFilterBlog"
import Footer from "../../components/Footer"
import HeaderBlog from "../../components/HeaderBlog"
import HighlightSliderBlog from "../../components/HighlightSliderBlog"
import Menu from "../../components/Menu"
import NewsletterBlog from "../../components/NewsletterBlog"
import { BlogSearchProvider } from "../../hooks/useBlogSearch"
import { IArticleRaw } from "../../interfaces/article"
import nodesToData from "../../utils/nodesToData"
import imgBlog from "../../images/thumbBlog"

const gridClasses = cx("m-auto main-container")

const BlogPage = ({ data: WpPosts }: any): JSX.Element => {
  const [recentPostsData]: IArticleRaw[][] = nodesToData(WpPosts)
  const title = "Blog";
  const thumbBlog = "https://ene-solucoes.s3.amazonaws.com/thumbBlog.png"
  
  return (
    <>
      <Helmet defaultTitle="eNe Soluções"
        title={title}
        titleTemplate="%s | eNe Soluções">
        {/* Primary Meta Tags  */}
        <title>Blog eNe Soluções</title>
        <meta name="title" content="Blog eNe Soluções" />
        <meta name="description" content="https://enesolucoes.com.br/blog" />

        {/* Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enesolucoes.com.br/blog" />
        <meta property="og:title" content="Blog eNe Soluções" />
        <meta property="og:description" content="https://enesolucoes.com.br/blog" />
        <meta property="og:image" content={thumbBlog} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://enesolucoes.com.br/blog" />
        <meta property="twitter:title" content="Blog eNe Soluções" />
        <meta property="twitter:description" content="https://enesolucoes.com.br/blog" />
        <meta property="twitter:image" content={thumbBlog} />
      </Helmet>
      <BlogSearchProvider>
        <main className={gridClasses}>
          <Menu activeMenu="blog" blog={true} />
        </main>
        <HeaderBlog />
        <section className="bg-white">
          <HighlightSliderBlog cardData={recentPostsData} />
          <main className={cx(gridClasses, "pt-16")}>
            <CategoriesFilterBlog posts={recentPostsData} categories={WpPosts.allWpCategory} />
          </main>
        </section>
        <section className="flex newsletter">
          <NewsletterBlog />
        </section>
        <Footer blog={true} />
      </BlogSearchProvider>
    </>
  )
}


export const query = graphql`
  query recentPosts {
    recentPosts: allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        uri
        title
        dateGmt
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt")
        contentType {
          node {
            id
          }
        }
        commentCount
        excerpt
      }
    }
    allWpCategory {
      nodes {
        name
      }
    }
  }
`

export default BlogPage
