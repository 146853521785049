import React from 'react';
import ArrowLeft from "../../images/arrow-left-blog.svg";
import ArrowRight from "../../images/arrow-right-blog.svg";
import "./styles.less";

import _get from "lodash/get";
import _map from "lodash/map";
import _random from "lodash/random";

import Slider from "react-slick";
import HighlightCardBlog from '../HighlightCardBlog';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";


import { IArticleRaw } from "../../interfaces/article";


interface Props {
  cardData: IArticleRaw[]
}

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any): JSX.Element => {
  return (
    <div {...props}>
      {children}
    </div>
  )
}

const HighlightSliderBlog = ({ cardData }: Props): JSX.Element => {
  const randNum = _random(0, cardData.length - 4)
  const cardDataLimit = cardData.slice(randNum, randNum + 4)
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <SlickButtonFix>
        <img src={ArrowLeft} alt="Slide Anterior" />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <img src={ArrowRight} alt="Slide Proximo" />
      </SlickButtonFix>
    ),
  }

  return (
    <Slider className="w-full slickCarousel bg-white" {...settings}>
      {_map(cardDataLimit, (card: CardsA) => {
        const cardId = _get(card, ["id"])
        return <HighlightCardBlog cardData={card} key={cardId} />
      }
      )}
    </Slider>
  )
}

export default HighlightSliderBlog;
