import cx from "classnames"
import React from "react"
import { useMedia } from "react-use-media"
import useBlogSearch, { BlogSearchProvider } from "../../hooks/useBlogSearch"
import SearchFormBlog from "../SearchFormBlog"
import * as styles from "./styles.module.css"

const HeaderBlog = (): JSX.Element => {

  const { setSearchParam } = useBlogSearch()

  React.useEffect(() => setSearchParam(""), [])
  const isTablet = useMedia({
    minWidth: 768,
  })

  const headerContainerClassNames = cx("bg-elephant ", styles.container, {
    [styles.tabletBG]: isTablet,
  })

  const contentWrapperClassNames = cx(
    "contentAlign m-auto md:w-[354px] lg:w-[65%] xl:w-[648px] xx:w-[648px] lg:items-center",
    styles.contentWrapper
  )

  const titleClassName =
    "contentAlign text-2.375xl text-white leading-[2.875rem] text-center font-bold font-barlow lg:text-5.5xl lg:leading-[1.2] mb-4"
  const descClassName =
    "contentAlign text-tiny text-center font-barlow font-light text-white lg:text-base w-[354px] lg:w-[85%] xl:w-[80%] xx:w-[80%] m-auto md:mb-8 pb-6"
  return (
    <section className={headerContainerClassNames}>
      <div className={contentWrapperClassNames}>
        <h2 className={titleClassName}>
          As principais novidades do mundo da
          <span className="text-cyan"> tecnologia</span> você encontra
          aqui.
        </h2>
        <p className={descClassName}>
          Pílulas semanais de conhecimento sobre tudo que é tendência no
          universo de TI disponíveis semanalmente para você.
        </p>
        <div className="xs:w-[100%] sm:w-[100%] w-[354px] lg:w-[60%] xl:w-[80%] xx:w-[80%] m-auto">
          <SearchFormBlog clearInput={true} />
        </div>
      </div>
    </section>
  )
}
const Wrapped = ({ ...props }) => (
  <BlogSearchProvider>
    <HeaderBlog {...props} />
  </BlogSearchProvider>
)

export default Wrapped
