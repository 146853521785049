import { Button } from "antd";
import cx from "classnames";
import React from 'react';
import Badges from '../Badges';
import "./styles.less";

import _get from "lodash/get";
import _truncate from "lodash/truncate";
interface Card {
  id: string
  uri: string
  title: string
  featuredImage: {
    node: {
      sourceUrl: string
    }
  }
  categories: {
    nodes: {
      name: string
    }
  }
  author: {
    node: {
      name: string
      avatar: {
        url: string
      }
    }
  }
  date: string
  commentCount: string
  excerpt: string
}

interface Props {
  cardData: Card
}

const HighlightCardBlog = ({ cardData }: Props): JSX.Element => {
  const authorName = _get(cardData, ["author", "node", "name"])
  const text = _truncate(_get(cardData, ["excerpt"]), { 'length': 305 })
  const title = _get(cardData, ["title"])
  const date = _get(cardData, ["date"])
  const link = _get(cardData, ["uri"])
  const tag = _get(cardData, ["categories", "nodes", "0", "name"])

  const tagIcon = tag.split(" ")[0];
  const tagText = tag.split(" ");
  tagText.shift();
  const regexIsThereEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/
  const regexResults = regexIsThereEmoji.test(tagIcon)

  const image = _get(cardData, ["featuredImage", "node", "sourceUrl"])

  const mainContent = cx("contentAlign sm:m-auto flex items-center items-center bg-white mainContentWrapper flex-col md:flex-row-reverse")
  const badgeContainer = cx("flex justify-start align-center")
  const h1Cont = cx("text-[38px] lg:text-[56px] font-bold font-barlow leading-tight lg:leading-[64px] my-5 h1Color")
  const pText = cx("font-light font-barlow text-[14px] lg:text-[16px] leading-[24px] text")
  const infoSide = cx("w-full md:w-5/12 lg:mt-8 infos")
  const imgSide = cx("sm:w-[100%] w-[335px] h-[263px] md:w-[408px] md:h-[415px] lg:w-[550px] lg:h-[507px] imgBg")
  const authorInfo = cx("text-[16px] leading-[24px] lg:text-base pb-4 m-0 author")

  return (
    <section className={mainContent}>
      <div className={infoSide}>
        <div className={badgeContainer}>
          {regexResults &&
            <Badges icon={tagIcon} index={0} text={tagText.join(" ")} />
          }
          {!regexResults &&
            <Badges index={0} text={`${tagIcon} ${tagText.join(" ")}`} />
          }
        </div>
        <h1 className={h1Cont}>{title}</h1>
        <p className={pText} dangerouslySetInnerHTML={{ __html: text }} />
        {/* <p className={authorInfo}>
          <strong>
            {`Por: ${authorName}`}
          </strong>
        </p> */}
        <p className="font-barlow text-[14px] leading-[22px] h1Color lg:mb-5">{date}</p>
        <Button href={`/blog/artigo${link}`}>
          Ler mais
        </Button>
      </div>
      <div className={imgSide}>
        <img className="rounded-3xl mt-8 w-full sm:w-[100%] md:h-[415px] xl:h-[475px] md:object-cover" src={image} alt="Imagem Card" />
      </div>
    </section>
  )
}

export default HighlightCardBlog;
