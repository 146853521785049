import React, {useState, useRef, useEffect} from "react";
import _drop from "lodash/drop"
import _trim from "lodash/trim"
import _get from "lodash/get"
import _filter from "lodash/filter"
import cx from "classnames";
import "./styles.less"
import ArrowLeft from "../../images/arrow-left-blog.svg"
import ArrowRight from "../../images/arrow-right-blog.svg"
import HamburguerMenu from "../../images/hamburguer-menu-blog.svg"
import { IArticleRaw } from "../../interfaces/article"
import RecentArticles from "../RecentArticles";



interface Props {
    posts: IArticleRaw[]
    categories: any
}

const contentWrapper = cx("flex flex-col w-full justify-start contentWrapper pt-8")
const menuContent = cx("flex items-center justify-start w-full relative menu-content")

const CategoriesFilterBlog = ({posts, categories}:Props): JSX.Element => {
    const [menu, setMenu] = useState(false)
    const [postsItens, setPostsItens] = useState(posts)
    const [itemSelected, setItemSelected] = useState("Todas as Categorias")
    const [scrollX, setScrollX] = useState(0)
    const scrollAmount = 320;
    const listRef = useRef<HTMLUListElement>(null)
    const containerL = useRef<HTMLDivElement>(null)
    const [maxScroll, setMaxScroll] = useState(0);
    const realCategories = _get(categories, 'nodes', "Sem Categoria");
    const withoutNoCategory = _filter(realCategories, (e :any) => e.name !== "Sem Categoria")
    const regexIsThereEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/
    const filtered = withoutNoCategory.map((e: any) => {
        const firstValue = e.name.split(" ")[0]
        const regexResults = regexIsThereEmoji.test(firstValue)
        if(!regexResults) return (e.name)
        else return ( _drop(e.name, 3).join(''))
    })

    useEffect(()=>{
        window.addEventListener("resize", () => setScrollX(0));
        localStorage.setItem('selected-category', itemSelected)
        setMaxScroll(Math.abs((containerL.current?.offsetWidth * -1) + listRef.current?.offsetWidth) * -1)
        return( window.removeEventListener('resize', ()=> setScrollX(0)))
    })
    
    const handlerClick = () => {
        setMenu(!menu)
    }
    
    const filterCat = (value: string): void => {
        value === "Todas as Categorias" ? setPostsItens(posts) : setPostsItens(posts.filter((post)=> (
             _trim(_drop(post.categories.nodes[0].name, 2).join('')) === value))
        )
        handlerClick()
        setItemSelected(value)
    }
    
    const handleClickPrevBtn = (): void => {
        scrollX >=  (scrollAmount) * -1 ? setScrollX(0) : setScrollX(scrollX + scrollAmount)
    }
    
    const handleClickNextBtn = (): void => {
        scrollX <= (maxScroll+scrollAmount)? setScrollX(maxScroll) : setScrollX(scrollX-scrollAmount)
    }

    return(
        <section className={contentWrapper}>
            <div className={menuContent}>
                <button className="menu px-5" onClick={handlerClick}>
                    <img src={HamburguerMenu} alt="Menu" />
                </button>
                <span className="spanText">Selecione as Categorias</span>
                <button className="arrow" onClick={handleClickPrevBtn}><img src={ArrowLeft} alt="Anterior" /></button>
                <div ref={containerL} className={`dropMenu absolute left-0 top-[55px] pl-4 ${menu ? "drop-down-active" : ""}`}>
                    <ul ref={listRef} className="listCont" style={{transform: `translateX(${scrollX}px)`}}>
                        <li className={itemSelected === "Todas as Categorias" ? "li-active" : ""}>
                            <button onClick={() => filterCat("Todas as Categorias")}>Todas as Categorias</button>
                        </li>
                        { filtered.map((element :any) => {
                            return(
                                <li className={itemSelected === `${element}` ? "li-active" : ""} key={element}>
                                    <button onClick={() => filterCat(`${element}`)}>{element}</button>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
                <button className="arrow" onClick={handleClickNextBtn}><img src={ArrowRight} alt="Próximo" /></button>
            </div>

            <div className="mt-10">
                {postsItens && <RecentArticles articles={postsItens} />}
            </div>
        </section>
    )
}

export default CategoriesFilterBlog
